import { Routes } from '@angular/router';
import { ColumnsSelectorComponent } from '@dashboard-components/data-browser/columns-selector/columns-selector.component';
import { DataBrowserWrapperComponent } from '@dashboard-components/data-browser/data-browser.component';
import { MultiSelectorFilter } from '@dashboard-components/data-browser/multi-selector-filter/multi-selector-filter.component';
import { CalendarFilterComponent } from '@dashboard-components/data-browser/panel-filters/calendar-filter/calendar-filter.component';
import { MultipleChoiceFilterComponent } from '@dashboard-components/data-browser/panel-filters/multiple-choice-filter/multiple-choice-filter.component';
import { SelectionFilterComponent } from '@dashboard-components/data-browser/panel-filters/selection-filter/selection-filter.component';
import { SingleChoiceFilterComponent } from '@dashboard-components/data-browser/panel-filters/single-choice-filter/single-choice-filter.component';
import { SliderFilterComponent } from '@dashboard-components/data-browser/panel-filters/slider-filter/slider-filter.component';
import { FilterChipTextPipe } from '@dashboard-components/data-browser/pipes/filterChipText.pipe';
import { ResolveFilteredviewTextPipe } from '@dashboard-components/data-browser/pipes/resolveFilteredviewText.pipe';
import { SpeedDialButtonComponent } from '@dashboard-components/data-browser/speed-dial-button/speed-dial-button.component';
import { AlbiImageWrapperComponent } from '@dashboard-components/detail/albi-image-wrapper/albi-image-wrapper.component';
import { AlbiInputWrapper } from '@dashboard-components/detail/albi-inpuit-wrapper/albi-input-wrapper.component';
import { DetailBottomBarComponent } from '@dashboard-components/detail/detail-bottom-bar/detail-bottom-bar.component';
import { DetailHeaderComponent } from '@dashboard-components/detail/detail-header/detail-header.component';
import { DetailHtabs } from '@dashboard-components/detail/detail-htabs/detail-htabs.component';
import { DetailPageWrapperComponent } from '@dashboard-components/detail/detail-page-wrapper/detail-page-wrapper.component';
import { DetailSectionComponent } from '@dashboard-components/detail/detail-section/detail-section.component';
import { GlassCounterComponent } from '@dashboard-components/glass-counter/glass-counter.component';
import { DataExplorerCalendarFilterComponent } from '@dashboard-components/section-data-explorer/filters/data-explorer-calendar-filter/data-explorer-calendar-filter.component';
import { DataExplorerMultipleChoiceFilterComponent } from '@dashboard-components/section-data-explorer/filters/data-explorer-multiple-choice-filter/data-explorer-multiple-choice-filter.component';
import { DataExplorerSelectionFilterComponent } from '@dashboard-components/section-data-explorer/filters/data-explorer-selection-filter/data-explorer-selection-filter.component';
import { DataExplorerSingleChoiceFilterComponent } from '@dashboard-components/section-data-explorer/filters/data-explorer-single-choice-filter/data-explorer-single-choice-filter.component';
import { DataExplorerSliderFilterComponent } from '@dashboard-components/section-data-explorer/filters/data-explorer-slider-filter/data-explorer-slider-filter.component';
import { SectionDataExplorerComponent } from '@dashboard-components/section-data-explorer/section-data-explorer.component';
import { TableWrapperComponent } from '@dashboard-components/table/table.component';
import { DashboardComponent } from 'src/dashboards/dashboard/dashboard.component';
import { CalculateQuantityPipe } from 'src/dashboards/pipes/calculateQuantityPipe';
import { DateFormatPipe } from 'src/dashboards/pipes/dateFormat.pipe';
import { DescriptionPipe } from 'src/dashboards/pipes/description.pipe';
import { GetFileFormatPipe } from 'src/dashboards/pipes/getFileFormat.pipe';
import { IncludedInArrayPipe } from 'src/dashboards/pipes/includedInArrayPipe';
import { ObjectKeysPipe } from 'src/dashboards/pipes/objectKeys.pipe';
import { ResolveCoordinatesPipe } from 'src/dashboards/pipes/resolveCoordinates.pipe';
import { ResolveCountryCodePipe } from 'src/dashboards/pipes/resolveCountryCode.pipe';
import { ResolveNestedObjectFieldPipe } from 'src/dashboards/pipes/resolveNestedObjectFieldPipe';
import { SliderValueFormatPipe } from 'src/dashboards/pipes/sliderFormat.pipe';
import { UserNameOrEmailPipe } from 'src/dashboards/pipes/userEmailOrName.pipe';
import { CalculateAgePipe } from 'src/dashboards/user/pipes/calculateAge.Pipe';
import { CalculateRatingsPercentagePipe } from 'src/dashboards/wine/pipes/calculateRatingsPercentage.pipe';
import { GetStatusLabelPipe } from 'src/dashboards/wine/pipes/getStatusLabel.pipe';
import { ImageStringBooleanPipe } from 'src/dashboards/wine/pipes/imageStringBoolean.pipe';
import { LocaleDescriptionPipe } from 'src/dashboards/wine/pipes/localeDescription.pipe';
import { ResolveFullnamePipe } from 'src/dashboards/wine/pipes/resolveFullname.pipe';
import { NotFoundComponent } from 'src/errros/not-found/not-found.component';
import { HomeComponent } from 'src/home/home.component';
import { AuthGuard } from 'src/login/guards/auth.guard';
import { LoginComponent } from 'src/login/login.component';
import { DestroyableSubscription } from 'src/utils/destroyable-subscription';

export const DECLARATIONS = [
    LoginComponent,
    HomeComponent,
    DashboardComponent,
    NotFoundComponent,
];

export const EXPORT_DECLARATIONS = [
    DestroyableSubscription,
    TableWrapperComponent,
    DataBrowserWrapperComponent,
    ColumnsSelectorComponent,
    MultipleChoiceFilterComponent,
    SingleChoiceFilterComponent,
    SelectionFilterComponent,
    SliderFilterComponent,
    CalendarFilterComponent,
    MultiSelectorFilter,
    SpeedDialButtonComponent,
    DescriptionPipe,
    DateFormatPipe,
    UserNameOrEmailPipe,
    GetFileFormatPipe,
    GetStatusLabelPipe,
    CalculateAgePipe,
    ResolveCountryCodePipe,
    SliderValueFormatPipe,
    ObjectKeysPipe,
    FilterChipTextPipe,
    ResolveFilteredviewTextPipe,
    ResolveFullnamePipe,
    DetailHeaderComponent,
    DetailSectionComponent,
    DetailBottomBarComponent,
    AlbiInputWrapper,
    LocaleDescriptionPipe,
    AlbiImageWrapperComponent,
    ImageStringBooleanPipe,
    SectionDataExplorerComponent,
    DataExplorerCalendarFilterComponent,
    DataExplorerMultipleChoiceFilterComponent,
    DataExplorerSelectionFilterComponent,
    DataExplorerSingleChoiceFilterComponent,
    DataExplorerSliderFilterComponent,
    ResolveNestedObjectFieldPipe,
    CalculateQuantityPipe,
    IncludedInArrayPipe,
    DetailPageWrapperComponent,
    DetailHtabs,
    CalculateRatingsPercentagePipe,
    ResolveCoordinatesPipe,
    GlassCounterComponent,
];


export const ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'wines',
                loadChildren: () => import('./../dashboards/wine/modules/wine.module').then(m => m.WineModule)
            },
            {
                path: 'companies',
                loadChildren: () => import('../dashboards/company/company.module').then(m => m.CompanyModule)
            },
            {
                path: 'document-pending',
                loadChildren: () => import('../dashboards/document-verification/document-verification.module').then(m => m.DocumentVerificationModule)
            },
            {
                path: 'companies/:id',
                loadChildren: () => import('../dashboards/company/company-detail/modules/company-detail.module').then(m => m.CompanyDetailModule)
            },
            {
                path: 'users',
                loadChildren: () => import('../dashboards/user/user.module').then(m => m.UserModule),
            },
            {
                path: 'users/:id',
                loadChildren: () => import('../dashboards/user/user-detail/modules/user-detail.module').then(m => m.UserDetailModule),
            },
            {
                path: 'dispensers',
                loadChildren: () => import('../dashboards/dispenser/dispenser.module').then(m => m.DispenserModule)
            },
            {
                path: 'dispensers/:id',
                loadChildren: () => import('../dashboards/dispenser/dispenser-detail/modules/dispenser-detail.module').then(m => m.DispenserDetailModule)
            },
            {
                path: 'tags',
                loadChildren: () => import('../dashboards/tags/modules/tag.module').then(m => m.TagModule)
            },
        ]
    },
    {
        path: 'not-found',
        component: NotFoundComponent
    },
    {
        path: '**',
        redirectTo: '/not-found'
    }
];
